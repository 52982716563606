import BlockScroller from "../BlockScroller";
import BlockHead from "../_BlockHead";
import {buildCssUrlString} from "../../../../utils/StringUtilities";
import ErrorBlock from "../ErrorBlock";
import {useContext} from "react";
import {StoreContext} from "../../../../stores/StoreLoader";
import {observer} from "mobx-react";
import {getAvatarUrl} from "../blockUtils";
import {blockObjectFactory} from "../blockUtils";
import {IBlockComponentProps} from "../_Block";
import {isJson} from "../../../admin/spinup/utilities";
import {getBoolean} from "../../../../utils/DataUtilities";

interface IStaffBlockProps extends IBlockComponentProps {
    blockObj: IStaffBlockObj
}

const StaffBlock = observer((props: IStaffBlockProps) => {
    try {
        const {modalStore} = useContext(StoreContext);
        const {blockObj} = props;

        let json_data = props.blockObj.blockModel.json_data;
        if (typeof json_data === 'string' && isJson(json_data)) json_data = JSON.parse(json_data);

        const displayStaffTitles = getBoolean(json_data.block.settings.displayStaffTitles);

        return <BlockScroller className="sb-staff-block"
                              blockRef={props.blockRef}
                              blockObj={props.blockObj}
                              htmlId={props.htmlId}
                              setShowExpandButton={props.setShowExpandButton}
                              outsideGrid={props.outsideGrid}>
            <BlockHead blockType={props.blockObj.blockType} editableTitle={blockObj.blockModel.title} blockId={props.blockObj.id} />
            <div className="sb-blockContent">
                <ul className="staff-members">
                    {blockObj?.staffMembers.map(staff => {

                        const modalObj = {
                            id: staff.id,
                            type: "schoolBlocksPerson",
                            blockObj: blockObjectFactory("person", {id: staff.id, blockModel: staff} as IPersonBlock),
                        }

                        const name = `${staff.fname} ${staff.lname}`;

                        return <li key={staff.id} className="row" title={name}>
                            <button onClick={() => modalStore.addModal(modalObj)}>
                                <div className="col-xs-3" style={{paddingRight: 0, marginRight: 0}}>
                                    <div className="sb-circular-profile-image sb-small-profile-image"
                                         style={{"backgroundImage": buildCssUrlString(getAvatarUrl(staff))}}
                                         title={name}>
                                    </div>
                                </div>
                                {staff.title && displayStaffTitles ?
                                    <div className="col-xs-9 sb-staff-name-with-title">
                                        <span className={"notranslate"}>{name}</span>
                                        <br/>
                                        <span className="sb-staff-title">{staff.title}</span>
                                    </div> : <div className="col-xs-9 sb-staff-name-without-title notranslate">
                                        <span>{name}</span>
                                    </div>}
                            </button>
                        </li>
                    })}
                </ul>
            </div>
        </BlockScroller>
    } catch (error) {
        return <ErrorBlock blockObj={{errorMessage: error}} />
    }
});

export default StaffBlock;
